import React, { PureComponent } from "react"
import { Row, Col } from 'react-bootstrap';
import Layout from '../components/layout';

const separatorCss = {
  position: 'relative',
  left: '-339px',
  boxSizing: 'border-box',
  width: '1908px',
  paddingLeft: '339px',
  paddingRight: '339px'
}

class IndexPage extends PureComponent {
  render() {
    return (
      <Layout>
        <div className='get-started'>
        <Row className="intro-row justify-content-md-center">
          <div className='spaced-hero'>
            <p className='spaced-hero-header margin-auto'>
              Get Started
            </p>
            <p className='spaced-hero-subheader margin-auto'>
              Do-It-Yourself resources to build your business your way on your time
            </p>
          </div>
        </Row>
        <Row className="strategy-row justify-content-md-center">
          <Col sm={4}>
            <div className='strategy-figure'>
              <img src="https://www.alituplife.com/wp-content/uploads/2020/10/idea-1.svg" alt="" loading="lazy"/>
              <p>Mindset</p>
            </div>
          </Col>
          <Col sm={4}>
            <div className='strategy-figure'>
              <img src="https://www.alituplife.com/wp-content/uploads/2020/10/idea-1.svg" alt="" loading="lazy"/>
              <p>Mindset</p>
            </div>
          </Col>
          <Col sm={4}>
            <div className='strategy-figure'>
              <img src="https://www.alituplife.com/wp-content/uploads/2020/10/idea-1.svg" alt="" loading="lazy"/>
              <p>Mindset</p>
            </div>
          </Col>
        </Row>
        <div className='section-separator' style={separatorCss}/>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
